import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

const Homesafety = ({ data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    homeSafety: {
      obstaclesInHouse: { isObstaclesInHouse: "", comment: "" },
      electricalCords: { isElectricalCords: "", comment: "" },
      noSlipMats: { isNoSlipMats: "", comment: "" },
      adequateLighting: { isAdequateLighting: "", comment: "" },
      handrailsOnStaircases: { isHandrailsOnStaircases: "", comment: "" },
      hotWaterHeater: { isHotWaterHeater: "", comment: "" },
      smokeDetectors: { isSmokeDetectors: "", comment: "" },
      carbonMonoxideDetectors: { isCarbonMonoxideDetectors: "", comment: "" },
      establishedEscapeRoute: { isEstablishedEscapeRoute: "", comment: "" },
      eventOfEmergency: { isEventOfEmergency: "", comment: "" },
    },
    improveQualityOfLife: {
      improveQualityOfLife: "",
      comment: "",
    },
    workOnItDropdown: {
      workOnItDropdown: "",
      comment: "",
    },
    abusedBySomeone: {
      isAbusedBySomeone: "",
      comment: "",
    },
    feelingLikeHarming: {
      isFeelingLikeHarming: "",
      isFeelingLikeHarmingSubAnswers: {
        whoDoYouFeel: "Who do you feel like harming?",
        atThisMoment: "",
        safePlace: "",
        assistYou: "",
      },
      comment: "",
    },
    afraidOfAnyone: {
      isAfraidOfAnyone: "",
      isAfraidOfAnyoneSubAnswers: {
        whoAreYouAfraidOf: "",
        whoIsHurtingYou: "",
        safePlace: "",
        assistYou: "",
      },
      comment: "",
    },
  });

  const { user = {} } = useSelector((state) => state?.auth ?? {});

  useEffect(() => {
    if (data && data.data) {
      setFormData(data.data);
    }
  }, [data]);

  const handleHomeSafetyChange = (key, field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      homeSafety: {
        ...prev.homeSafety,
        [key]: {
          ...prev.homeSafety[key],
          [field]: value,
        },
      },
    }));
  };

  const handleQualityOfLifeChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      improveQualityOfLife: {
        ...prev.improveQualityOfLife,
        improveQualityOfLife: value,
      },
    }));
  };

  const handleWorkOnItChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      workOnItDropdown: {
        ...prev.workOnItDropdown,
        workOnItDropdown: value,
      },
    }));
  };

  const handleAbusedChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      abusedBySomeone: {
        ...prev.abusedBySomeone,
        isAbusedBySomeone: value,
      },
    }));
  };

  const handleFeelingLikeHarmingChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      feelingLikeHarming: {
        ...prev.feelingLikeHarming,
        isFeelingLikeHarming: value,
      },
    }));
  };

  const handleSubAnswerChange = (key, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      feelingLikeHarming: {
        ...prev.feelingLikeHarming,
        isFeelingLikeHarmingSubAnswers: {
          ...prev.feelingLikeHarming.isFeelingLikeHarmingSubAnswers,
          [key]: value,
        },
      },
    }));
  };

  const handleAfraidOfAnyoneChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      afraidOfAnyone: {
        ...prev.afraidOfAnyone,
        isAfraidOfAnyone: value,
      },
    }));
  };

  const handleAfraidSubAnswerChange = (key, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      afraidOfAnyone: {
        ...prev.afraidOfAnyone,
        isAfraidOfAnyoneSubAnswers: {
          ...prev.afraidOfAnyone.isAfraidOfAnyoneSubAnswers,
          [key]: value,
        },
      },
    }));
  };

  const handleCommentChange = (field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        comment: value,
      },
    }));
  };

  const handleSubmit = async () => {
    const numYear = user.year;
    const yearToUse = (function () {
      if (!numYear) return new Date().getFullYear();

      const validYears = [2023, 2024];
      const yearStr = String(numYear).trim();
      const yearNum = Number(yearStr);

      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();

    try {
      const payload = {
        memberId: user.memberId,
        year: yearToUse,
        name: "Home safety and personal goals",
        data: formData,
      };

      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-homesafety`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data.code === 200) {
        setMessage("Home safety information updated successfully!");
        setIsEditing(false);
      } else {
        setMessage(
          "Failed to update home safety: " +
            (response.data.message || "Unknown error"),
        );
      }
    } catch (error) {
      console.error("Error updating home safety:", error);
      setMessage(
        "Failed to update home safety. Please check your network connection and try again.",
      );
    }
  };

  const getButtonClass = (value) => {
    return `btn ${value === "1" ? "btn-primary" : "btn-outline-primary"}`;
  };

  if (!data) return null;
  const safetyItems = [
    {
      key: "obstaclesInHouse",
      label: "Obstacles in House",
      field: "isObstaclesInHouse",
    },
    {
      key: "electricalCords",
      label: "Electrical Cords",
      field: "isElectricalCords",
    },
    { key: "noSlipMats", label: "No Slip Mats", field: "isNoSlipMats" },
    {
      key: "adequateLighting",
      label: "Adequate Lighting",
      field: "isAdequateLighting",
    },
    {
      key: "handrailsOnStaircases",
      label: "Handrails on Staircases",
      field: "isHandrailsOnStaircases",
    },
    {
      key: "hotWaterHeater",
      label: "Hot Water Heater",
      field: "isHotWaterHeater",
    },
    {
      key: "smokeDetectors",
      label: "Smoke Detectors",
      field: "isSmokeDetectors",
    },
    {
      key: "carbonMonoxideDetectors",
      label: "Carbon Monoxide Detectors",
      field: "isCarbonMonoxideDetectors",
    },
    {
      key: "establishedEscapeRoute",
      label: "Established Escape Route",
      field: "isEstablishedEscapeRoute",
    },
    {
      key: "eventOfEmergency",
      label: "Event of Emergency",
      field: "isEventOfEmergency",
    },
  ];

  return (
    <div
      className="tab-pane fade show active"
      id="pills-HPG"
      role="tabpanel"
      aria-labelledby="pills-HPG-tab"
    >
      <div className="containers mt-5">
        {message && (
          <Alert
            severity={message.includes("successfully") ? "success" : "error"}
            className="mb-4"
          >
            {message ||
              (message.includes("successfully")
                ? "Here is a gentle confirmation that your action was successful."
                : "Something went wrong. Please try again.")}
          </Alert>
        )}

        <form onSubmit={(e) => e.preventDefault()}>
          <h2 className="mb-4">
            Home Safety & Personal goals
            <hr />
          </h2>

          {/* Home Safety Section */}
          <div className="mb-4">
            <h5 className="mb-3">Home Safety Assessment</h5>
            {safetyItems.map(({ key, label, field }) => (
              <div key={key} className="mb-3 border-bottom pb-2">
                <div className="d-flex align-items-center gap-4">
                  <div className="w-50">
                    <label className="form-label mb-0">{label}</label>
                  </div>
                  <div className="d-flex gap-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={key}
                        checked={formData.homeSafety[key][field] === "1"}
                        onChange={() => handleHomeSafetyChange(key, field, "1")}
                        disabled={!isEditing}
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={key}
                        checked={formData.homeSafety[key][field] === "2"}
                        onChange={() => handleHomeSafetyChange(key, field, "2")}
                        disabled={!isEditing}
                      />
                      <label className="form-check-label">No</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={key}
                        checked={formData.homeSafety[key][field] === "3"}
                        onChange={() => handleHomeSafetyChange(key, field, "3")}
                        disabled={!isEditing}
                      />
                      <label className="form-check-label">N/A</label>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Add comment..."
                      value={formData.homeSafety[key].comment}
                      onChange={(e) =>
                        handleHomeSafetyChange(key, "comment", e.target.value)
                      }
                      disabled={!isEditing}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />

          {/* Quality of Life Section */}
          <div className="mb-4">
            <label className="form-label">
              What is something you can work on to improve your quality of life?
            </label>
            <input
              type="text"
              className="form-control mb-2"
              value={formData.improveQualityOfLife.improveQualityOfLife}
              onChange={(e) => handleQualityOfLifeChange(e.target.value)}
              disabled={!isEditing}
            />
            <select
              className="form-select"
              value={formData.workOnItDropdown.workOnItDropdown}
              onChange={(e) => handleWorkOnItChange(e.target.value)}
              disabled={!isEditing}
            >
              <option value="">Please Select</option>
              <option value="1">I want to work on it.</option>
              <option value="2">
                I am already working on it but need some help.
              </option>
              <option value="3">
                I am already working on it and don't need any help.
              </option>
              <option value="4">I am not interested at this time.</option>
            </select>
          </div>
          <hr />

          {/* Abused questions */}
          <div className="mb-4">
            <label className="form-label">Are you abused by anyone?</label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                id="AbuseYes"
                name="isAbusedBySomeone"
                checked={formData.abusedBySomeone?.isAbusedBySomeone === "1"}
                onChange={() => handleAbusedChange("1")}
                disabled={!isEditing}
              />
              <label className="form-check-label" htmlFor="AbuseYes">
                Yes
              </label>
            </div>

            <div className="form-check mt-2">
              <input
                className="form-check-input"
                type="radio"
                id="AbuseNo"
                name="isAbusedBySomeone"
                checked={formData.abusedBySomeone?.isAbusedBySomeone === "2"}
                onChange={() => handleAbusedChange("2")}
                disabled={!isEditing}
              />
              <label className="form-check-label" htmlFor="AbuseNo">
                No
              </label>
            </div>

            <textarea
              className="form-control mt-2"
              placeholder="Add a comment..."
              value={formData.abusedBySomeone.comment}
              onChange={(e) =>
                handleCommentChange("abusedBySomeone", e.target.value)
              }
              disabled={!isEditing}
            />
          </div>
          <hr />

          {/* Feeling Like Harming Section */}
          <div className="mb-4">
            <label className="form-label">
              Are you feeling like harming someone?
            </label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                id="HarmYes"
                name="isFeelingLikeHarming"
                checked={
                  formData.feelingLikeHarming?.isFeelingLikeHarming === "1"
                }
                onChange={() => handleFeelingLikeHarmingChange("1")}
                disabled={!isEditing}
              />
              <label className="form-check-label" htmlFor="HarmYes">
                Yes
              </label>
            </div>

            <div className="form-check mt-2">
              <input
                className="form-check-input"
                type="radio"
                id="HarmNo"
                name="isFeelingLikeHarming"
                checked={
                  formData.feelingLikeHarming?.isFeelingLikeHarming === "2"
                }
                onChange={() => handleFeelingLikeHarmingChange("2")}
                disabled={!isEditing}
              />
              <label className="form-check-label" htmlFor="HarmNo">
                No
              </label>
            </div>

            {formData.feelingLikeHarming?.isFeelingLikeHarming === "1" && (
              <div className="mt-3">
                <label className="form-label">
                  Who do you feel like harming?
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  value={
                    formData.feelingLikeHarming.isFeelingLikeHarmingSubAnswers
                      .whoDoYouFeel
                  }
                  onChange={(e) =>
                    handleSubAnswerChange("whoDoYouFeel", e.target.value)
                  }
                  disabled={!isEditing}
                />

                <label className="form-label">
                  At this moment, who do you feel like harming?
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  value={
                    formData.feelingLikeHarming.isFeelingLikeHarmingSubAnswers
                      .atThisMoment
                  }
                  onChange={(e) =>
                    handleSubAnswerChange("atThisMoment", e.target.value)
                  }
                  disabled={!isEditing}
                />

                <label className="form-label">Where is a safe place?</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  value={
                    formData.feelingLikeHarming.isFeelingLikeHarmingSubAnswers
                      .safePlace
                  }
                  onChange={(e) =>
                    handleSubAnswerChange("safePlace", e.target.value)
                  }
                  disabled={!isEditing}
                />

                <label className="form-label">Who can assist you?</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  value={
                    formData.feelingLikeHarming.isFeelingLikeHarmingSubAnswers
                      .assistYou
                  }
                  onChange={(e) =>
                    handleSubAnswerChange("assistYou", e.target.value)
                  }
                  disabled={!isEditing}
                />
              </div>
            )}
          </div>
          <hr />

          {/* Afraid of Anyone Section */}
          <div className="mb-4">
            <label className="form-label">Are you afraid of anyone?</label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                id="AfraidYes"
                name="isAfraidOfAnyone"
                checked={formData.afraidOfAnyone?.isAfraidOfAnyone === "1"}
                onChange={() => handleAfraidOfAnyoneChange("1")}
                disabled={!isEditing}
              />
              <label className="form-check-label" htmlFor="AfraidYes">
                Yes
              </label>
            </div>

            <div className="form-check mt-2">
              <input
                className="form-check-input"
                type="radio"
                id="AfraidNo"
                name="isAfraidOfAnyone"
                checked={formData.afraidOfAnyone?.isAfraidOfAnyone === "2"}
                onChange={() => handleAfraidOfAnyoneChange("2")}
                disabled={!isEditing}
              />
              <label className="form-check-label" htmlFor="AfraidNo">
                No
              </label>
            </div>

            {formData.afraidOfAnyone?.isAfraidOfAnyone === "1" && (
              <div className="mt-3">
                <label className="form-label">Who are you afraid of?</label>

                <input
                  type="text"
                  className="form-control mb-2"
                  value={
                    formData.afraidOfAnyone.isAfraidOfAnyoneSubAnswers
                      .whoAreYouAfraidOf
                  }
                  onChange={(e) =>
                    handleAfraidSubAnswerChange(
                      "whoAreYouAfraidOf",
                      e.target.value,
                    )
                  }
                  disabled={!isEditing}
                />

                <label className="form-label">Who is hurting you?</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  value={
                    formData.afraidOfAnyone.isAfraidOfAnyoneSubAnswers
                      .whoIsHurtingYou
                  }
                  onChange={(e) =>
                    handleAfraidSubAnswerChange(
                      "whoIsHurtingYou",
                      e.target.value,
                    )
                  }
                  disabled={!isEditing}
                />

                <label className="form-label">Where is a safe place?</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  value={
                    formData.afraidOfAnyone.isAfraidOfAnyoneSubAnswers.safePlace
                  }
                  onChange={(e) =>
                    handleAfraidSubAnswerChange("safePlace", e.target.value)
                  }
                  disabled={!isEditing}
                />

                <label className="form-label">Who can assist you?</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  value={
                    formData.afraidOfAnyone.isAfraidOfAnyoneSubAnswers.assistYou
                  }
                  onChange={(e) =>
                    handleAfraidSubAnswerChange("assistYou", e.target.value)
                  }
                  disabled={!isEditing}
                />
              </div>
            )}
          </div>
          <hr />

          {/* Edit/Save/Cancel Buttons */}
          <div className="col-md-12 mt-4 text-center">
            {!isEditing ? (
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => setIsEditing(true)}
              >
                Edit
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-success me-2"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    if (data && data.data) {
                      setFormData(data.data);
                    }
                    setIsEditing(false);
                    setMessage("");
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Homesafety;
