import React, { useState } from "react";
import { useSelector } from "react-redux";
import Alert from "../../components/Alert/Alert";

const Phq2 = ({ data }) => {
  const user = useSelector((state) => state.auth.user);
  const [message, setMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    worryTooMuch: {
      isWorryTooMuch: "",
      comment: "",
    },
    feelAfraid: {
      isFeelAfraid: "",
      comment: "",
    },
    overPastTwoWeeks: {
      littleInterestOrPleasure: {
        notAtAll: "",
        severalDays: "",
        moreThanHalfTheDays: "",
        nearlyEveryDay: "",
        comment: "",
      },
      feelingDownDepressed: {
        notAtAll: "",
        severalDays: "",
        moreThanHalfTheDays: "",
        nearlyEveryDay: "",
        comment: "",
      },
      phq2score: {
        lessThanThree: "",
        threeOrMore: "",
        phq2score: "0",
        threeOrMoreSubAnswer: {
          depressionScreening: {
            phq9score: "0",
            havingLittleInterest: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            feelingDownDepressed: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            troubleFalling: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            feelingTired: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            poorAppetite: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            feelingBadAboutYourself: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            troubleConcentratingOnThings: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            movingOrSpeaking: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            hurtingYourself: {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            difficultProblems: {
              notDifficultAtAll: "",
              someWhatDifficult: "",
              veryDifficult: "",
              extremelyDifficult: "",
              other: "",
              notApplicable: "",
              comment: "",
            },
            comment: "",
          },
        },
      },
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const [section, ...parts] = name.split("-");

    setFormData((prevData) => {
      const newData = { ...prevData };

      if (section === "overPastTwoWeeks") {
        const [question, answer] = parts;
        const options = [
          "notAtAll",
          "severalDays",
          "moreThanHalfTheDays",
          "nearlyEveryDay",
        ];

        options.forEach((opt) => {
          newData.overPastTwoWeeks[question][opt] = opt === answer ? "1" : "";
        });

        if (answer === "comment") {
          newData.overPastTwoWeeks[question].comment = value;
        }

        const score = calculatePHQ2Score(newData);
        newData.overPastTwoWeeks.phq2score.phq2score = score.toString();
        newData.overPastTwoWeeks.phq2score.lessThanThree = score < 3 ? "1" : "";
        newData.overPastTwoWeeks.phq2score.threeOrMore = score >= 3 ? "1" : "";
      } else if (section === "phq9") {
        const [question, answer] = parts;
        const options = [
          "notAtAll",
          "several",
          "moreThanHalfTheDays",
          "nearlyEveryDay",
        ];

        if (question === "difficultProblems") {
          const diffOptions = [
            "notDifficultAtAll",
            "someWhatDifficult",
            "veryDifficult",
            "extremelyDifficult",
            "other",
            "notApplicable",
          ];
          diffOptions.forEach((opt) => {
            newData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.difficultProblems[
              opt
            ] = opt === answer ? "1" : "";
          });
        } else {
          if (answer === "comment") {
            if (question === "comment") {
              newData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.comment =
                value;
            } else {
              newData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening[
                question
              ].comment = value;
            }
          } else {
            options.forEach((opt) => {
              newData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening[
                question
              ][opt] = opt === answer ? "1" : "";
            });
          }
        }

        const phq9Score = calculatePHQ9Score(newData);
        newData.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.phq9score =
          phq9Score.toString();
      } else if (section === "worryTooMuch" || section === "feelAfraid") {
        const field =
          section === "worryTooMuch" ? "isWorryTooMuch" : "isFeelAfraid";
        if (parts[0] === field) {
          newData[section][field] = value === "yes" ? "1" : "2";
        } else if (parts[0] === "comment") {
          newData[section].comment = value;
        }
      }

      return newData;
    });
  };

  const calculatePHQ2Score = (data) => {
    const scoreMap = {
      notAtAll: 0,
      severalDays: 1,
      moreThanHalfTheDays: 2,
      nearlyEveryDay: 3,
    };

    let totalScore = 0;
    ["littleInterestOrPleasure", "feelingDownDepressed"].forEach((question) => {
      Object.entries(data.overPastTwoWeeks[question]).forEach(
        ([key, value]) => {
          if (key !== "comment" && value === "1") {
            totalScore += scoreMap[key];
          }
        },
      );
    });

    return totalScore;
  };

  const calculatePHQ9Score = (data) => {
    const scoreMap = {
      notAtAll: 0,
      several: 1,
      moreThanHalfTheDays: 2,
      nearlyEveryDay: 3,
    };

    let totalScore = 0;
    const screening =
      data.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening;
    const questions = [
      "havingLittleInterest",
      "feelingDownDepressed",
      "troubleFalling",
      "feelingTired",
      "poorAppetite",
      "feelingBadAboutYourself",
      "troubleConcentratingOnThings",
      "movingOrSpeaking",
      "hurtingYourself",
    ];

    questions.forEach((question) => {
      Object.entries(screening[question]).forEach(([key, value]) => {
        if (key !== "comment" && value === "1") {
          totalScore += scoreMap[key];
        }
      });
    });

    return totalScore;
  };

  const handleSubmit = async () => {
    const yearToUse = (function () {
      const numYear = user.year;
      if (!numYear) return new Date().getFullYear();
      const validYears = [2023, 2024];
      const yearStr = String(numYear).trim();
      const yearNum = Number(yearStr);
      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-phq2score`,
        {
          method: "PUT",
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            memberId: user.memberId,
            year: yearToUse,
            name: "Phq2",
            data: formData,
          }),
        },
      );

      if (!response.ok) throw new Error("Network response was not ok");
      setMessage("Form updated successfully!");
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating PHQ-2 score:", error);
      setMessage("Failed to update form. Please try again.");
    }
  };

  const handleCancel = () => {
    if (data?.data) setFormData(data.data);
    setIsEditing(false);
    setMessage("");
  };

  if (!data) return null;

  return (
    <div className="w-full max-w-4xl mx-auto p-6">
      {message && <Alert className="mb-4">{message}</Alert>}

      <h2 className="text-2xl font-bold mb-4">PHQ2/PHQ9</h2>
      <hr className="mb-6" />

      <div className="flex justify-center gap-4 mt-6">
        {!isEditing ? (
          <button
            type="button"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </button>
        ) : (
          <>
            <button
              type="button"
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              type="button"
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Phq2;
