// Sdoh.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { LiteracySection } from "./LiteracySection";
import { HousingSection } from "./HousingSection";
import { SocialSection } from "./SocialSection";
import { EmploymentSection } from "./EmploymentSection";
import { SubstanceSection } from "./SubstanceSection";
import { AdvanceCareSection } from "./AdvanceCareSection";
import { RecommendationsSection } from "./RecommendationsSection";
import { FoodSection } from "./FoodSection";

const Sdoh = ({ data }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    school: {
      lessThanThirdGrade: "",
      lessThanEighthGrade: "",
      lessThanTwelthGrade: "",
      completedTwelthGrade: "",
      attendedOrgraduated: "",
      comment: "",
    },
    fillingMedicalForm: {
      notAtAllConfident: "",
      notVeryConfident: "",
      confident: "",
      veryConfident: "",
      comment: "",
    },
    writtenInfo: {
      veryDifficult: "",
      someWhatDifficult: "",
      easy: "",
      veryEasy: "",
      comment: "",
    },
    prescriptionBottle: {
      veryDifficult: "",
      someWhatDifficult: "",
      easy: "",
      veryEasy: "",
      comment: "",
    },
    currentlyLiveSituation: {
      currentlyLiveSituation: "",
      comment: "",
    },
    currentLive: {
      home: "",
      apartment: "",
      assistedLiving: "",
      nursingHome: "",
      homeless: "",
      homelessSubAnswer: {
        describe: "",
      },
      other: "",
      otherSubAnswer: {
        describe: "",
      },
      comment: "",
    },
    thinkAboutPlace: {
      bugsAntsMice: "",
      mold: "",
      leadPaintOrPipes: "",
      lackOfHeat: "",
      ovenOrStove: "",
      waterLeaks: "",
      none: "",
      comment: "",
    },
    currentlyLivingWith: {
      alone: "",
      aloneSubAnswer: {
        describe: "",
      },
      spouse: "",
      partner: "",
      relative: "",
      family: "",
      friend: "",
      personalCareWorker: "",
      comment: "",
    },
    lastTwelveMonths: {
      isLastTwelveMonths: "",
      lastTwelveMonthsSubAnswers: {
        lastTwelveMonthSelect: "",
        comment: "",
      },
      comment: "",
    },
    dailyLiving: {
      isDailyLiving: "",
      comment: "",
    },
    social: {
      rateYourHealth: {
        excellent: "",
        good: "",
        fair: "",
        poor: "",
        comment: "",
      },
      socialActivities: {
        often: "",
        sometimes: "",
        almostNever: "",
        never: "",
        comment: "",
      },
      relyOn: {
        isRelyOn: "",
        comment: "",
      },
      goOutToMeet: {
        often: "",
        sometimes: "",
        never: "",
        comment: "",
      },
      careGiver: {
        isCareGiver: "",
        isCareGiverSubAnswer: {
          describe: "",
        },
        comment: "",
      },
    },
    employement: {
      currentlyEmployed: {
        isCurrentlyEmployed: "",
        comment: "",
      },
      interestedEmployment: {
        isInterestedEmployment: "",
        comment: "",
      },
      volunteerCurrently: {
        isVolunteerCurrently: "",
        comment: "",
      },
    },
    substanceUse: {
      tobaccoUse: {
        current: "",
        currentSubAnswer: {
          type: {
            cigarettes: "",
            cigarettesSubAnswer: {
              oneToThree: "",
              halfAPack: "",
              onePack: "",
              moreThanOne: "",
              other: "",
              otherSubAnswer: {
                describe: "",
              },
              comment: "",
              interestedInSmoking: {
                interestedInSmoking: "",
                comment: "",
              },
              thingsPreventing: {
                educationNeeded: "",
                socialSupport: "",
                environmentalFactors: "",
                stress: "",
                selfConfidence: "",
                lackOfMotivation: "",
                notAPriority: "",
                other: "",
                none: "",
                comment: "",
              },
            },
            cigars: "",
            chewingTobacco: "",
            vaping: "",
            other: "",
            otherSubAnswer: {
              describe: "",
            },
            comment: "",
          },
          smokingCessation: {
            smokingCessation: "",
            comment: "",
          },
        },
        former: "",
        formerSubAnswer: {
          when: {
            withinLastYear: "",
            withinLastThreeYear: "",
            moreYearAgo: "",
            comment: "",
          },
          type: {
            cigarettes: "",
            cigarettesSubAnswer: {
              oneToThree: "",
              halfAPack: "",
              onePack: "",
              moreThanOne: "",
              other: "",
              otherSubAnswer: {
                describe: "",
              },
              comment: "",
            },
            cigars: "",
            chewingTobacco: "",
            vaping: "",
            other: "",
            otherSubAnswer: {
              describe: "",
            },
            comment: "",
          },
        },
        never: "",
        comment: "",
      },
      alcoholUse: {
        current: "",
        currentSubAnswer: {
          howMnyTimes: {
            isNone: "",
            oneOrMore: "",
            oneOrMoreSubAnswers: {
              howOften: {
                never: "",
                monthly: "",
                twoTimesMonth: "",
                twoTimesWeek: "",
                fourTimesWeek: "",
                comment: "",
              },
              howManyDrinks: {
                oneOrTwo: "",
                threeOrFour: "",
                fiveOrSix: "",
                sevenEight: "",
                tenOrMore: "",
                comment: "",
              },
              sixOrMore: {
                never: "",
                lessThanMonthly: "",
                monthly: "",
                weekly: "",
                daily: "",
                comment: "",
              },
              auditScore: "",
              weeklyAverage: "",
              comment: "",
            },
            comment: "",
          },
          currentlyInRecovery: {
            isCurrentlyInRecovery: "",
            comment: "",
          },
        },
        former: "",
        formerSubAnswer: {
          howMnyTimes: {
            isNone: "",
            oneOrMore: "",
            oneOrMoreSubAnswers: {
              howOften: {
                never: "",
                monthly: "",
                twoTimesMonth: "",
                twoTimesWeek: "",
                fourTimesWeek: "",
                comment: "",
              },
              howManyDrinks: {
                oneOrTwo: "",
                threeOrFour: "",
                fiveOrSix: "",
                sevenEight: "",
                tenOrMore: "",
                comment: "",
              },
              sixOrMore: {
                never: "",
                lessThanMonthly: "",
                monthly: "",
                weekly: "",
                daily: "",
                comment: "",
              },
              auditScore: "",
              weeklyAverage: "",
              comment: "",
            },
            comment: "",
          },
          currentlyInRecovery: {
            isCurrentlyInRecovery: "",
            comment: "",
          },
        },
        never: "",
        comment: "",
      },
      educationProvided: {
        isEducationProvided: "",
        comment: "",
      },
    },
    educationGuidlines: {
      pastYear: {
        isNone: "",
        oneOrMore: "",
        oneOrMoreSubAnswers: {
          educationOrResources: "",
          comment: "",
        },
        comment: "",
      },
    },
    advanceCarePlanning: {
      healthCareProxy: {
        isHealthCareProxy: "",
        isHealthCareProxySubAnswer: {
          name: "",
          relationship: "",
        },
        comment: "",
      },
      powerAttorney: {
        isPowerAttorney: "",
        isPowerAttorneySubAnswer: {
          name: "",
          relationship: "",
        },
        comment: "",
      },
      advanceDirective: {
        isAdvanceDirective: "",
        isAdvanceDirectiveSubAnswer: {
          kept: "",
        },
        comment: "",
      },
      educatedOnAdvanceCarePlanning: {
        isEducatedOnAdvanceCarePlanning: "",
        comment: "",
      },
      declinesDiscussion: {
        isDeclinesDiscussion: "",
        comment: "",
      },
    },
    food: {
      foodWouldRunOut: {
        oftenTrue: "",
        oftenTrueSubAnswers: {
          foodWeBought: "",
          dontHaveMoney: "",
          comment: "",
        },
        sometimesTrue: "",
        sometimesTrueSubAnswers: {
          foodWeBought: "",
          dontHaveMoney: "",
          comment: "",
        },
        neverTrue: "",
        comment: "",
      },
    },
    recommendations: {
      recommendationsSubAnswer: [
        { smokingOrTobacco: "", comment: "" },
        { substanceUse: "", comment: "" },
        { foodDisparity: "", comment: "" },
        { literacy: "", comment: "" },
        { socialSupport: "", comment: "" },
        { advanceCare: "", comment: "" },
        { transportation: "", comment: "" },
        { careManagement: "", comment: "" },
        { followUp: "", comment: "" },
      ],
      comment: "",
    },
  });

  const { user = {} } = useSelector((state) => state?.auth ?? {});

  useEffect(() => {
    if (data && data.data) {
      setFormData(data.data);
    }
  }, [data]);

  const handleSubmit = async () => {
    const yearToUse = (function () {
      const numYear = user.year;
      if (!numYear) return new Date().getFullYear();
      const validYears = [2023, 2024];
      const yearStr = String(numYear).trim();
      const yearNum = Number(yearStr);
      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();

    try {
      const payload = {
        memberId: user.memberId,
        year: yearToUse,
        name: "SDOH",
        data: formData,
      };

      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-self-assessment`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data.code === 200) {
        setMessage("SDOH information updated successfully!");
        setIsEditing(false);
      } else {
        setMessage(
          "Failed to update SDOH: " +
            (response.data.message || "Unknown error"),
        );
      }
    } catch (error) {
      console.error("Error updating SDOH:", error);
      setMessage(
        "Failed to update SDOH. Please check your network connection and try again.",
      );
    }
  };

  const handleCancel = () => {
    if (data && data.data) {
      setFormData(data.data);
    }
    setIsEditing(false);
    setMessage("");
  };

  return (
    <div className="tab-pane fade show active" id="pills-sdoh" role="tabpanel">
      <div className="containers mt-5">
        {message && (
          <div
            className={`alert ${
              message.includes("successfully")
                ? "alert-success"
                : "alert-danger"
            } mb-4`}
          >
            {message}
          </div>
        )}

        <form onSubmit={(e) => e.preventDefault()}>
          <h2 className="mb-4">SDOH</h2>
          <hr />

          <LiteracySection
            formData={formData}
            isEditing={isEditing}
            setFormData={setFormData}
          />
          <hr />

          <HousingSection
            formData={formData}
            isEditing={isEditing}
            setFormData={setFormData}
          />
          <hr />

          <SocialSection
            formData={formData}
            isEditing={isEditing}
            setFormData={setFormData}
          />
          <hr />

          <EmploymentSection
            formData={formData}
            isEditing={isEditing}
            setFormData={setFormData}
          />
          <hr />

          <SubstanceSection
            formData={formData}
            isEditing={isEditing}
            setFormData={setFormData}
          />
          <hr />
          <AdvanceCareSection
            formData={formData}
            isEditing={isEditing}
            setFormData={setFormData}
          />
          <hr />
          <FoodSection
            formData={formData}
            isEditing={isEditing}
            setFormData={setFormData}
          />
          <hr />
          <RecommendationsSection
            formData={formData}
            isEditing={isEditing}
            setFormData={setFormData}
          />
          <hr />
          <div className="col-md-12 mt-4 text-center">
            {!isEditing ? (
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => setIsEditing(true)}
              >
                Edit
              </button>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-success me-2"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Sdoh;
