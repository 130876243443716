import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

const Preventive = ({ data }) => {
  const [formData, setFormData] = useState({
    preventiveCare: {
      pastThreeYearsSubAnswers: [],
      comment: "",
    },
    oneTimeScreenForAbdominal: {
      isOneTimeScreenForAbdominal: "",
      isOneTimeScreenForAbdominalSubAnswer: {
        isEducationProvided: "",
        comment: "",
      },
      comment: "",
    },
    oneTimeScreenForHepatitis: {
      isoneTimeScreenForHepatitis: "",
      isoneTimeScreenForHepatitisSubAnswer: {
        isEducationProvided: "",
        comment: "",
      },
      comment: "",
    },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (data && data.data) {
      setFormData(data.data);
    }
  }, [data]);

  const handleScreeningChange = (index, field, value) => {
    if (!isEditing) return;

    const updatedScreenings = [
      ...formData.preventiveCare.pastThreeYearsSubAnswers,
    ];
    updatedScreenings[index] = {
      ...updatedScreenings[index],
      [field]: value,
    };

    setFormData((prev) => ({
      ...prev,
      preventiveCare: {
        ...prev.preventiveCare,
        pastThreeYearsSubAnswers: updatedScreenings,
      },
    }));
  };

  const handleOneTimeScreenChange = (screenType, field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [screenType]: {
        ...prev[screenType],
        [field]: value,
      },
    }));
  };

  const handleCommentChange = (section, comment) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        comment,
      },
    }));
  };

  const handleSubmit = async () => {
    const numYear = user.year;
    //handle any exceptions that occur due to year value
    const yearToUse = (function () {
      if (!numYear) return new Date().getFullYear();

      const validYears = [2023, 2024];
      const yearStr = String(numYear).trim();
      const yearNum = Number(yearStr);

      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();
    try {
      const payload = {
        memberId: user.memberId,
        year: yearToUse,
        name: "PreventiveCare",
        data: formData,
      };

      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-preventive-care`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data.code === 200) {
        setMessage("Preventive care information updated successfully!");

        setIsEditing(false);
      } else {
        setMessage(
          "Failed to update preventive care: " +
            (response.data.message || "Unknown error"),
        );
      }
    } catch (error) {
      console.error("Error updating preventive care:", error);
      setMessage(
        "Failed to update preventive care. Please check your network connection and try again.",
      );
    }
  };

  const { user = {} } = useSelector((state) => state?.auth ?? {});

  return (
    <div
      className="tab-pane fade show active"
      id="pills-prevent"
      role="tabpanel"
      aria-labelledby="pills-prevent-tab"
    >
      <div className="containers mt-4">
        {message && (
          <Alert
            severity={message.includes("successfully") ? "success" : "error"}
            className="mb-4"
          >
            {message ||
              (message.includes("successfully")
                ? "Here is a gentle confirmation that your action was successful."
                : "Something went wrong. Please try again.")}
          </Alert>
        )}

        <h2 className="mb-4">Preventive Care</h2>
        <p>In the past three years have you had?</p>

        <div className="table-responsive mb-4">
          <table className="table table-bordered">
            <thead className="table-light">
              <tr>
                <th>Screen</th>
                <th>Answer</th>
                <th>Date</th>
                <th>Method</th>
                <th>Education Provided/Recommendation</th>
              </tr>
            </thead>
            <tbody>
              {formData.preventiveCare.pastThreeYearsSubAnswers.map(
                (screening, index) => (
                  <tr key={screening.screen}>
                    <td>{screening.screen}</td>
                    <td>
                      <select
                        className="form-select"
                        value={screening.answer}
                        onChange={(e) =>
                          handleScreeningChange(index, "answer", e.target.value)
                        }
                        disabled={!isEditing}
                      >
                        <option value="0">Select</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="date"
                        className="form-control"
                        value={screening.date || ""}
                        onChange={(e) =>
                          handleScreeningChange(index, "date", e.target.value)
                        }
                        disabled={!isEditing}
                      />
                    </td>
                    <td>{screening.method}</td>
                    <td>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={screening.recommendation === "1"}
                          onChange={(e) =>
                            handleScreeningChange(
                              index,
                              "recommendation",
                              e.target.checked ? "1" : "0",
                            )
                          }
                          disabled={!isEditing}
                        />
                      </div>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </div>

        <div className="mb-4">
          <label className="form-label">
            One time screen for Abdominal Aortic Aneurysm if male with history
            of smoking, age 65 - 75
          </label>
          <div className="btn-group">
            {["Yes", "No", "N/A"].map((option, index) => (
              <button
                key={option}
                type="button"
                className={`btn ${
                  formData.oneTimeScreenForAbdominal
                    .isOneTimeScreenForAbdominal === (index + 1).toString()
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                onClick={() =>
                  handleOneTimeScreenChange(
                    "oneTimeScreenForAbdominal",
                    "isOneTimeScreenForAbdominal",
                    (index + 1).toString(),
                  )
                }
                disabled={!isEditing}
              >
                {option}
              </button>
            ))}
          </div>
          <textarea
            className="form-control mt-2"
            placeholder="Comments"
            value={formData.oneTimeScreenForAbdominal.comment}
            onChange={(e) =>
              handleCommentChange("oneTimeScreenForAbdominal", e.target.value)
            }
            disabled={!isEditing}
          />
        </div>

        <div className="mb-4">
          <label className="form-label">
            One time screen for Hepatitis C if born between 1945 - 1965
          </label>
          <div className="btn-group">
            {["Yes", "No", "N/A"].map((option, index) => (
              <button
                key={option}
                type="button"
                className={`btn ${
                  formData.oneTimeScreenForHepatitis
                    .isoneTimeScreenForHepatitis === (index + 1).toString()
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                onClick={() =>
                  handleOneTimeScreenChange(
                    "oneTimeScreenForHepatitis",
                    "isoneTimeScreenForHepatitis",
                    (index + 1).toString(),
                  )
                }
                disabled={!isEditing}
              >
                {option}
              </button>
            ))}
          </div>
          <textarea
            className="form-control mt-2"
            placeholder="Comments"
            value={formData.oneTimeScreenForHepatitis.comment}
            onChange={(e) =>
              handleCommentChange("oneTimeScreenForHepatitis", e.target.value)
            }
            disabled={!isEditing}
          />
        </div>

        <div className="col-md-12 mt-4 text-center">
          {!isEditing ? (
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-success me-2"
                onClick={handleSubmit}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  if (data && data.data) {
                    setFormData(data.data);
                  }
                  setIsEditing(false);
                  setMessage("");
                }}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Preventive;
