import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

const PatientSummary = ({ user }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchAssessmentData();
  }, []);

  const fetchAssessmentData = async () => {
    const yearToUse = getValidYear(user?.year);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authentication token not found");
      }

      if (!user?.memberId) {
        throw new Error("Member ID is required");
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-memberUpdatedAssessment?memberId=${user.memberId}&year=${yearToUse}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      const data = await response.json();

      if (data.code === 200) {
        setAssessmentData(data.data);
      } else {
        throw new Error(data.message || "Failed to fetch assessment data");
      }
    } catch (err) {
      setError(
        err.message ||
          "Error fetching assessment data. Please try again later.",
      );
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!termsAccepted) {
      setIsDialogOpen(true);
      return;
    }

    setSubmitting(true);
  };

  const handleSubmitv2 = async () => {
    const yearToUse = getValidYear(user?.year);

    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Authentication token not found");

      const payload = {
        memberId: user?.memberId,
        year: yearToUse,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/approved-selfReporting-assessment`,
        {
          method: "PUT",
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
      );

      const data = await response.json();
      if (data.code === 200) {
        setMessage("Assessment submitted successfully");
        // Uncomment to navigate after successful submission
        // navigate("/MemberPortal/Profile");
      } else {
        throw new Error(data.message || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error submitting assessment:", error);
      setMessage("Failed to submit assessment. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const getValidYear = (numYear) => {
    if (!numYear) return new Date().getFullYear();

    const validYears = [2023, 2024];
    const yearStr = String(numYear).trim();
    const yearNum = Number(yearStr);

    return validYears.includes(yearNum) ||
      validYears.map(String).includes(yearStr)
      ? yearNum
      : new Date().getFullYear();
  };

  const getActivityLabel = (score) => {
    const labels = {
      1: "Independent",
      2: "Needs Some Help",
      3: "Needs Moderate Help",
      4: "Needs Significant Help",
      5: "Completely Dependent",
    };
    return labels[score] || "Not Assessed";
  };

  const renderSocialAndEmployment = () => {
    const socialData = assessmentData.self?.data?.social || {};
    const employmentData = assessmentData.self?.data?.employement || {};

    return (
      <div className="card mb-4 shadow-sm" style={{ border: "solid" }}>
        <div
          className="card-header py-3"
          style={{ backgroundColor: "#0056b3" }}
        >
          <h5 className="card-title mb-0" style={{ color: "white" }}>
            Social and Employment
          </h5>
        </div>
        <div className="card-body">
          <div className="row gy-3">
            <div className="col-12 col-md-6">
              <strong>Social Activities:</strong>
              <p>
                {socialData.socialActivities?.never === "1"
                  ? "Never participates in social activities"
                  : socialData.socialActivities?.almostNever === "1"
                  ? "Almost never participates in social activities"
                  : socialData.socialActivities?.sometimes === "1"
                  ? "Sometimes participates in social activities"
                  : socialData.socialActivities?.often === "1"
                  ? "Often participates in social activities"
                  : "N/A"}
              </p>
            </div>
            <div className="col-12 col-md-6">
              <strong>Employment Status:</strong>
              <p>
                {employmentData.currentlyEmployed?.isCurrentlyEmployed === "1"
                  ? `Currently Employed: ${
                      employmentData.currentlyEmployed?.comment || "Yes"
                    }`
                  : "Not Currently Employed"}
              </p>
            </div>
            <div className="col-12 col-md-6">
              <strong>Volunteering:</strong>
              <p>
                {employmentData.volunteerCurrently?.isVolunteerCurrently === "1"
                  ? `Volunteering: ${
                      employmentData.volunteerCurrently?.comment || "Yes"
                    }`
                  : "Not Volunteering"}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSubstanceUse = () => {
    const substanceData = assessmentData.self?.data?.substanceUse || {};
    const tobaccoUse = substanceData.tobaccoUse || {};
    const alcoholUse = substanceData.alcoholUse || {};

    return (
      <div className="card mb-4 shadow-sm" style={{ border: "solid" }}>
        <div
          className="card-header py-3"
          style={{ backgroundColor: "#0056b3" }}
        >
          <h5 className="card-title mb-0" style={{ color: "white" }}>
            Substance Use
          </h5>
        </div>
        <div className="card-body">
          <div className="row gy-3">
            <div className="col-12 col-md-6">
              <strong>Tobacco Use:</strong>
              <p>
                {tobaccoUse.current
                  ? "Currently Uses Tobacco"
                  : tobaccoUse.former
                  ? "Former Tobacco User"
                  : tobaccoUse.never === "1"
                  ? "Never Used Tobacco"
                  : "N/A"}
              </p>
              {tobaccoUse.current && (
                <p>
                  Types:{" "}
                  {Object.entries(tobaccoUse.currentSubAnswer?.type || {})
                    .filter(
                      ([key, value]) => value === "1" && key !== "comment",
                    )
                    .map(([key]) => key)
                    .join(", ")}
                </p>
              )}
            </div>
            <div className="col-12 col-md-6">
              <strong>Alcohol Use:</strong>
              <p>
                {alcoholUse.current
                  ? "Currently Uses Alcohol"
                  : alcoholUse.former
                  ? "Former Alcohol User"
                  : alcoholUse.never === "1"
                  ? "Never Used Alcohol"
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMedicalHistory = () => {
    const medicalData = assessmentData.medical?.data || {};
    const healthServices = medicalData.healthServices || {};
    const treatments = medicalData.treatments || {};
    const pastTwelveMonths =
      medicalData.pastTwelveMonths?.pastTwelveMonthsSubAnswers || {};

    return (
      <div className="card mb-4 shadow-sm" style={{ border: "solid" }}>
        <div
          className="card-header py-3"
          style={{ backgroundColor: "#0056b3" }}
        >
          <h5 className="card-title mb-0" style={{ color: "white" }}>
            Medical History
          </h5>
        </div>
        <div className="card-body">
          <div className="row gy-3">
            <div className="col-12 col-md-6">
              <strong>Health Services Utilized:</strong>
              <ul className="list-unstyled">
                {Object.entries(healthServices)
                  .filter(([key, value]) => value === "1" && key !== "comment")
                  .map(([key]) => (
                    <li key={key}>{key}</li>
                  ))}
              </ul>
            </div>
            <div className="col-12 col-md-6">
              <strong>Current Treatments:</strong>
              <ul className="list-unstyled">
                {Object.entries(treatments)
                  .filter(([key, value]) => value === "1" && key !== "comment")
                  .map(([key]) => (
                    <li key={key}>{key}</li>
                  ))}
              </ul>
            </div>
            <div className="col-12">
              <strong>Past 12 Months Medical Events:</strong>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    Primary Care Physician Visits:{" "}
                    {pastTwelveMonths.pcp?.isTwo === "1" ? "2" : "N/A"}
                  </p>
                  <p>
                    Emergency Room Visits:{" "}
                    {pastTwelveMonths.emergencyRoom?.isNone === "1"
                      ? "None"
                      : "Yes"}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    Hospitalizations:{" "}
                    {pastTwelveMonths.hospitalOvernight?.isOne === "1"
                      ? "1"
                      : "None"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderPreventiveCare = () => {
    const preventiveData =
      assessmentData.preventive?.data?.preventiveCare
        ?.pastThreeYearsSubAnswers || [];

    return (
      <div className="card mb-4 shadow-sm" style={{ border: "solid" }}>
        <div
          className="card-header py-3"
          style={{ backgroundColor: "#0056b3" }}
        >
          <h5 className="card-title mb-0" style={{ color: "white" }}>
            Preventive Care Screenings
          </h5>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Screening</th>
                  <th>Completed</th>
                  <th>Recommended</th>
                </tr>
              </thead>
              <tbody>
                {preventiveData.map((screening, index) => (
                  <tr key={index}>
                    <td>{screening.screen}</td>
                    <td>{screening.answer === "1" ? "Yes" : "No"}</td>
                    <td>{screening.recommendation === "1" ? "Yes" : "No"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-50 p-4">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger mx-3 my-4" role="alert">
        {error}
      </div>
    );
  }

  if (!assessmentData) {
    return (
      <div className="alert alert-info mx-3 my-4" role="alert">
        No assessment data available.
      </div>
    );
  }

  return (
    <div className="containers-fluid px-3 px-md-4 py-4">
      {/* Alert Modal */}
      {message && (
        <Alert
          severity={message.includes("successfully") ? "success" : "error"}
          className="mb-4"
        >
          {message ||
            (message.includes("successfully")
              ? "Here is a gentle confirmation that your action was successful."
              : "Something went wrong. Please try again.")}
        </Alert>
      )}
      {/* Demographics Card */}
      <div className="card mb-4 shadow-sm" style={{ border: "solid" }}>
        <div
          className="card-header py-3"
          style={{ backgroundColor: "#0056b3" }}
        >
          <h5 className="card-title mb-0" style={{ color: "white" }}>
            Demographics
          </h5>
        </div>
        <div className="card-body">
          <div className="row gy-3">
            <div className="col-12 col-md-6">
              <div className="border-bottom pb-2">
                <label className="text-muted small d-block">Full Name</label>
                <span className="fw-medium">
                  {assessmentData.demographics?.data?.firstName}{" "}
                  {assessmentData.demographics?.data?.lastName}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="border-bottom pb-2">
                <label className="text-muted small d-block">Email</label>
                <span className="fw-medium overflow-hidden text-break">
                  {assessmentData.demographics?.data?.email}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="border-bottom pb-2">
                <label className="text-muted small d-block">Member ID</label>
                <span className="fw-medium">
                  {assessmentData.demographics?.data?.memberId}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="border-bottom pb-2">
                <label className="text-muted small d-block">Address</label>
                <span className="fw-medium">
                  {assessmentData.demographics?.data?.address},
                  <br className="d-md-none" />
                  {assessmentData.demographics?.data?.city},{" "}
                  {assessmentData.demographics?.data?.state}{" "}
                  {assessmentData.demographics?.data?.zip}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Activities Card */}
      <div className="card mb-4 shadow-sm" style={{ border: "solid" }}>
        <div
          className="card-header 0py-3"
          style={{ backgroundColor: "#0056b3" }}
        >
          <h5 className="card-title mb-0" style={{ color: "white" }}>
            Activities of Daily Living
          </h5>
        </div>
        <div className="card-body">
          <div className="list-group list-group-flush">
            {Object.entries(
              assessmentData.activities?.data?.difficultyWithActivities || {},
            ).map(
              ([activity, data]) =>
                activity !== "totalScore" &&
                activity !== "totalScoreComment" &&
                activity !== "comment" && (
                  <div key={activity} className="list-group-item px-0">
                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center gap-2">
                      <span className="text-capitalize fw-medium">
                        {activity}
                      </span>
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted small">
                          {getActivityLabel(data.score)}
                        </span>
                        <span className="badge bg-primary">
                          {data.score} pts
                        </span>
                      </div>
                    </div>
                  </div>
                ),
            )}
          </div>

          <div className="alert alert-info mt-4 mb-0">
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center gap-2">
              <strong>Total Score</strong>
              <span className="fs-5">
                {
                  assessmentData.activities?.data?.difficultyWithActivities
                    ?.totalScore
                }{" "}
                pts
              </span>
            </div>
            {assessmentData.activities?.data?.difficultyWithActivities
              ?.totalScoreComment && (
              <strong>
                {
                  assessmentData.activities?.data?.difficultyWithActivities
                    ?.totalScoreComment
                }
              </strong>
            )}
          </div>
        </div>
      </div>

      {/* Family History Card */}
      <div className="card mb-4 shadow-sm" style={{ border: "solid" }}>
        <div
          className="card-header py-3"
          style={{ backgroundColor: "#0056b3" }}
        >
          <h5 className="card-title mb-0" style={{ color: "white" }}>
            Family History
          </h5>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table table-hover mb-0">
              <thead className="table-light">
                <tr>
                  <th>Family Member</th>
                  <th>Medical Condition</th>
                  <th>Cause of Death</th>
                </tr>
              </thead>
              <tbody>
                {assessmentData.family?.data?.familyHistory?.familyHistorySubAnswers?.map(
                  (history, index) => (
                    <tr key={index}>
                      <td className="fw-medium">{history.familyMember}</td>
                      <td>{history.medicalCondition}</td>
                      <td>{history.causeofDeath}</td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {renderSocialAndEmployment()}
      {renderSubstanceUse()}
      {renderMedicalHistory()}
      {renderPreventiveCare()}
      {/* Terms & Conditions Modal */}
      {/* Modal */}
      {isDialogOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1050, // Ensure it's above other content
          }}
        >
          <div
            style={{
              background: "white",
              padding: "20px",
              maxWidth: "600px",
              width: "100%",
              borderRadius: "8px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div className="modal-containers">
              <div className="modal-header">
                <h5 className="modal-title">
                  <b>Terms & Conditions</b>
                </h5>
              </div>
              <div className="modal-body">
                <div className="modal-content">
                  <p>
                    Please read and accept the terms and conditions before
                    submitting your assessment.
                  </p>
                  <div
                    className="border rounded p-3"
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    <div className="terms-content">
                      <h6>Terms and Conditions</h6>
                      <p>
                        Your health plan has contracted with Focus Care to
                        conduct a health exam on all of its Medicare members,
                        including you. The health exam includes questions to
                        help your health plan learn more about your current
                        health. The exam may also find things that could affect
                        your health. The results of the exam will help your
                        health plan and your doctor keep you as healthy as
                        possible.
                      </p>

                      <h6>Personal Health Information (PHI)</h6>
                      <p>
                        Personal health information, or PHI, is information in
                        your medical record that identifies the record as your
                        record. PHI includes things like your date of birth,
                        age, address, telephone number, and your medical
                        history.
                      </p>

                      <h6>Privacy Protection</h6>
                      <p>
                        Most of the time, Focus Care will not release your
                        personal information without your permission. Measures
                        are in place to prevent your personal information from
                        being accidentally released in writing, including by use
                        of a computer, or orally. You may request more
                        information about how your personal information is
                        protected.
                      </p>

                      <h6>Information Sharing</h6>
                      <p>
                        There are times when Focus Care is allowed to release
                        your personal information without your permission. For
                        example:
                      </p>
                      <ul>
                        <li>
                          Your medical information may be given to other health
                          care providers who take care of you
                        </li>
                        <li>
                          The results of this exam will be sent to your health
                          plan and to your doctor
                        </li>
                        <li>
                          Focus Care may release your personal health
                          information to a 'business associate'
                        </li>
                      </ul>

                      <h6>Business Associates</h6>
                      <p>
                        A 'business associate' is another agency that Focus Care
                        uses to do things, such as billing. We require our
                        'business associates' to have security measures in place
                        to prevent your personal information from being
                        accidentally released in writing, including by use of a
                        computer, or orally.
                      </p>

                      <h6>Legal Requirements</h6>
                      <p>
                        Focus Care may be required to release your personal
                        health information, without your permission, by law,
                        including statutes, regulations, or valid court orders.
                      </p>

                      <h6>Additional Permissions</h6>
                      <p>
                        Focus Care will obtain your permission to use or release
                        your personal health information for any other reason.
                      </p>

                      <h6>Important Notes</h6>
                      <ul>
                        <li>
                          Focus Care will not disclose your individual personal
                          health information to your employer, union, or any
                          other employee organization without your permission
                        </li>
                        <li>
                          The information obtained today and any applicable lab
                          results may be sent to your primary care physician
                          (PCP)
                        </li>
                        <li>
                          You can request this information in a different
                          language if needed
                        </li>
                      </ul>

                      <p className="mt-4">
                        <strong>
                          By accepting these terms, you agree to allow Focus
                          Care to release the results of your medical exam to
                          your health plan and to your doctor.
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer" style={{ padding: "10px" }}>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setIsDialogOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={async () => {
                      try {
                        const token = localStorage.getItem("token");
                        if (!token)
                          throw new Error("Authentication token not found");

                        const response = await fetch(
                          `${process.env.REACT_APP_API_BASE_URL}/memberPortal/terms-and-conditions`,
                          {
                            method: "PUT",
                            headers: {
                              Authorization: `${token}`,
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              memberId: user?.memberId,
                              year: getValidYear(),
                              termsAndConditions: false,
                            }),
                          },
                        );

                        const data = await response.json();

                        if (data.code === 200) {
                          setTermsAccepted(true);
                          setIsDialogOpen(false);
                          setMessage(
                            "Terms and conditions accepted successfully!",
                          );
                        } else {
                          throw new Error(
                            data.message ||
                              "Failed to accept terms and conditions",
                          );
                        }
                      } catch (error) {
                        console.error("Error accepting terms:", error);
                        setMessage(
                          "Failed to accept terms and conditions. Please try again.",
                        );
                      }
                    }}
                  >
                    Accept Terms
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Submit Section */}
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center gap-3">
            <div className="d-flex gap-2">
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={submitting}
              >
                {submitting ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Submitting...
                  </>
                ) : (
                  "Agree Terms"
                )}
              </button>

              <button
                className="btn btn-primary"
                onClick={handleSubmitv2}
                disabled={!termsAccepted}
              >
                {submitting ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Submitting...
                  </>
                ) : (
                  "Submit Assessment"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientSummary;
