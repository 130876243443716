export const RecommendationsSection = ({
  formData,
  isEditing,
  setFormData,
}) => {
  const handleRecommendationChange = (key) => {
    if (!isEditing) return;

    const currentRecommendations =
      formData.recommendations.recommendationsSubAnswer || [];
    const recommendation =
      currentRecommendations.find((r) => Object.keys(r)[0] === key) || {};
    const updatedValue = recommendation[key] === "1" ? "" : "1";

    const updatedRecommendations = currentRecommendations.map((r) => {
      if (Object.keys(r)[0] === key) {
        return { [key]: updatedValue, comment: r.comment || "" };
      }
      return r;
    });

    setFormData((prev) => ({
      ...prev,
      recommendations: {
        ...prev.recommendations,
        recommendationsSubAnswer: updatedRecommendations,
      },
    }));
  };

  const recommendations = [
    {
      key: "smokingOrTobacco",
      label: "Discuss smoking and tobacco cessation options with provider",
    },
    {
      key: "substanceUse",
      label: "Discuss substance use counseling with provider",
    },
    { key: "foodDisparity", label: "Resources for Food/Housing Disparity" },
    { key: "literacy", label: "Literacy" },
    { key: "socialSupport", label: "Social support evaluation" },
    {
      key: "advanceCare",
      label: "Discuss Advanced Care Planning with provider and family",
    },
    { key: "transportation", label: "Transportation assistance" },
    { key: "careManagement", label: "Case management referral for follow up" },
    { key: "followUp", label: "Follow up with Clinician/Specialist" },
  ];

  return (
    <section className="mb-5">
      <h2 className="h4 mb-3">Recommendations</h2>
      <div className="mb-4">
        {recommendations.map((rec) => (
          <div key={rec.key} className="mb-2 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id={`rec_${rec.key}`}
              checked={formData.recommendations.recommendationsSubAnswer?.some(
                (r) => r[rec.key] === "1",
              )}
              onChange={() => handleRecommendationChange(rec.key)}
              disabled={!isEditing}
            />
            <label className="form-check-label" htmlFor={`rec_${rec.key}`}>
              {rec.label}
            </label>
          </div>
        ))}
      </div>
    </section>
  );
};
