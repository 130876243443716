import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../store/actions/auth_action";
import "./static_files/css/mpapp.css";

const styles = `
  .register-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
    padding: 20px 0;
  }

  .register-card {
    background: white;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }

  .form-floating {
    margin-bottom: 1rem;
  }

  .form-control {
    border: 1px solid #ced4da;
    border-radius: 8px;
  }

  .form-control:focus {
    border-color: #0D6EFD;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.15);
  }

  .btn-primary {
    background: #0D6EFD;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    padding: 20px;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }

  .btn-primary:hover {
    background: #0b5ed7;
  }

  .btn-outline-primary {
    border-color: #0D6EFD;
    color: #0D6EFD;
    padding: 10px;
    font-weight: 500;
  }

  .btn-outline-primary:hover,
  .btn-outline-primary.active {
    background-color: #0D6EFD;
    border-color: #0D6EFD;
    color: white;
  }

  .auth-link {
    color: #0D6EFD;
    text-decoration: none;
  }
  
  .auth-link:hover {
    text-decoration: underline;
  }

  .invalid-feedback {
    font-size: 12px;
    margin-top: 4px;
  }

  .alert {
    border-radius: 8px;
    border: none;
    padding: 1rem;
  }

  @media (max-width: 768px) {
    .card-body {
      padding: 1.5rem !important;
    }
  }
`;

function Register() {
  const [formData, setFormData] = useState({
    memberId: "",
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    gender: "",
    address: "",
    phoneNumber: "",
    userName: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === "dateOfBirth" ? `${value}T00:00:00` : value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.memberId) formErrors.memberId = "Member ID is required";
    if (!formData.firstName) formErrors.firstName = "First Name is required";
    if (!formData.lastName) formErrors.lastName = "Last Name is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      formErrors.email = "Email is invalid";
    if (!formData.dateOfBirth)
      formErrors.dateOfBirth = "Date of Birth is required";
    if (!formData.gender) formErrors.gender = "Gender is required";
    if (!formData.address) formErrors.address = "Address is required";
    if (!formData.phoneNumber)
      formErrors.phoneNumber = "Phone Number is required";
    if (!formData.userName) formErrors.userName = "Username is required";
    if (!formData.password) formErrors.password = "Password is required";
    if (formData.password.length < 6)
      formErrors.password = "Password must be at least 6 characters";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        console.log("Attempting registration with:", formData);
        await dispatch(authActions.register(formData));
        console.log("Registration successful");
        navigate("/login");
      } catch (error) {
        console.error("Registration failed:", {
          error: error,
          message: error.message,
          stack: error.stack,
        });
        setErrors((prev) => ({
          ...prev,
          submit: "Registration failed. Please try again or contact support.",
        }));
      }
    }
  };

  const handleMembersAlreadyLogin = async () => {
    // sessionStorage.setItem("clientLogin", true);
    try {
      navigate(`/MemberPortal/Login`);
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <style>{styles}</style>
      <div className="register-container d-flex align-items-center">
        <div className="containers">
          <div className="row justify-content-center">
            <div className="col-12 col-xl-10">
              <div className="register-card card border-0">
                <div className="card-body p-4 p-sm-5">
                  <div className="text-center mb-4">
                    <img
                      src="https://focuscares.com/wp-content/uploads/elementor/thumbs/logo-pcsu71jmplrr1f3b7mtv083rbyula7p5imfik70y8o.png"
                      alt="Logo"
                      className="img-fluid mb-4"
                      style={{ maxWidth: "180px", height: "auto" }}
                    />
                    <h4 className="mb-4 text-secondary">Register Member</h4>
                  </div>

                  {(error || errors.submit) && (
                    <div
                      className="alert alert-danger d-flex align-items-center mb-4"
                      role="alert"
                    >
                      <i className="fas fa-exclamation-circle me-2"></i>
                      <small>{error || errors.submit}</small>
                    </div>
                  )}

                  <form onSubmit={handleSubmit} className="needs-validation">
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.memberId ? "is-invalid" : ""
                            }`}
                            id="memberId"
                            name="memberId"
                            placeholder="Member ID"
                            value={formData.memberId}
                            onChange={handleChange}
                            required
                          />
                          <label htmlFor="memberId">Member ID</label>
                          {errors.memberId && (
                            <div className="invalid-feedback">
                              {errors.memberId}
                            </div>
                          )}
                        </div>

                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.userName ? "is-invalid" : ""
                            }`}
                            id="userName"
                            name="userName"
                            placeholder="Username"
                            value={formData.userName}
                            onChange={handleChange}
                            required
                          />
                          <label htmlFor="userName">Username</label>
                          {errors.userName && (
                            <div className="invalid-feedback">
                              {errors.userName}
                            </div>
                          )}
                        </div>

                        <div className="form-floating mb-3">
                          <input
                            type="date"
                            className={`form-control ${
                              errors.dateOfBirth ? "is-invalid" : ""
                            }`}
                            id="dateOfBirth"
                            name="dateOfBirth"
                            value={formData.dateOfBirth.split("T")[0]}
                            onChange={handleChange}
                            required
                          />
                          <label htmlFor="dateOfBirth">Date of Birth</label>
                          {errors.dateOfBirth && (
                            <div className="invalid-feedback">
                              {errors.dateOfBirth}
                            </div>
                          )}
                        </div>

                        <div className="form-floating mb-3">
                          <input
                            type="tel"
                            className={`form-control ${
                              errors.phoneNumber ? "is-invalid" : ""
                            }`}
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                          />
                          <label htmlFor="phoneNumber">Phone Number</label>
                          {errors.phoneNumber && (
                            <div className="invalid-feedback">
                              {errors.phoneNumber}
                            </div>
                          )}
                        </div>

                        <div className="form-floating mb-3">
                          <textarea
                            className={`form-control ${
                              errors.address ? "is-invalid" : ""
                            }`}
                            id="address"
                            name="address"
                            placeholder="Address"
                            value={formData.address}
                            onChange={handleChange}
                            style={{ height: "100px" }}
                            required
                          />
                          <label htmlFor="address">Address</label>
                          {errors.address && (
                            <div className="invalid-feedback">
                              {errors.address}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.firstName ? "is-invalid" : ""
                            }`}
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                          />
                          <label htmlFor="firstName">First Name</label>
                          {errors.firstName && (
                            <div className="invalid-feedback">
                              {errors.firstName}
                            </div>
                          )}
                        </div>

                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            }`}
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                          />
                          <label htmlFor="lastName">Last Name</label>
                          {errors.lastName && (
                            <div className="invalid-feedback">
                              {errors.lastName}
                            </div>
                          )}
                        </div>

                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            id="email"
                            name="email"
                            placeholder="name@example.com"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                          <label htmlFor="email">Email Address</label>
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>

                        <div className="form-floating mb-3">
                          <input
                            type="password"
                            className={`form-control ${
                              errors.password ? "is-invalid" : ""
                            }`}
                            id="password"
                            name="password"
                            placeholder="Password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                          <label htmlFor="password">Password</label>
                          {errors.password && (
                            <div className="invalid-feedback">
                              {errors.password}
                            </div>
                          )}
                        </div>

                        <div className="mb-4">
                          <label className="form-label d-block">Gender</label>
                          <div className="btn-group w-100" role="group">
                            {["Male", "Female", "Other"].map((option) => (
                              <React.Fragment key={option}>
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="gender"
                                  id={`gender${option}`}
                                  value={option}
                                  checked={formData.gender === option}
                                  onChange={handleChange}
                                  required
                                />
                                <label
                                  className={`btn btn-outline-primary ${
                                    formData.gender === option ? "active" : ""
                                  }`}
                                  htmlFor={`gender${option}`}
                                >
                                  {option}
                                </label>
                              </React.Fragment>
                            ))}
                          </div>
                          {errors.gender && (
                            <div className="text-danger small mt-1">
                              {errors.gender}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <button
                      className="btn btn-primary w-100 mt-4"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Registering Member...
                        </>
                      ) : (
                        "Register Member"
                      )}
                    </button>
                  </form>

                  <p className="text-center mt-4 mb-0">
                    {/* Already have an account? <a href="/MemberPortal/Login" className="auth-link">Log In</a> */}
                    Already have an account?{" "}
                    <span
                      onClick={handleMembersAlreadyLogin}
                      style={{
                        color: "#007bff", // Link color
                        cursor: "pointer", // Pointer cursor for interactivity
                        fontSize: "16px",
                        fontFamily: "Inter, sans-serif",
                      }}
                    >
                      Log In
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
