export const EmploymentSection = ({ formData, isEditing, setFormData }) => {
  const handleChange = (field, subField, value) => {
    if (!isEditing) return;
    setFormData((prev) => ({
      ...prev,
      employement: {
        ...prev.employement,
        [field]: {
          ...prev.employement[field],
          [subField]: value,
        },
      },
    }));
  };

  return (
    <section className="mb-5">
      <h2 className="h4 mb-3">Employment</h2>

      {/* Currently Employed */}
      <div className="mb-4">
        <label className="form-label text-primary">
          Are you currently employed?
        </label>
        <div className="d-flex gap-2">
          {[
            { value: "1", label: "Yes" },
            { value: "2", label: "No" },
          ].map((option) => (
            <div key={option.value}>
              <input
                type="radio"
                className="btn-check"
                name="currently_employed"
                id={`employed_${option.value}`}
                checked={
                  formData.employement?.currentlyEmployed
                    ?.isCurrentlyEmployed === option.value
                }
                onChange={() =>
                  handleChange(
                    "currentlyEmployed",
                    "isCurrentlyEmployed",
                    option.value,
                  )
                }
                disabled={!isEditing}
              />
              <label
                className="btn btn-outline-primary"
                htmlFor={`employed_${option.value}`}
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Interested in Employment */}
      <div className="mb-4">
        <label className="form-label text-primary">
          Are you interested in employment?
        </label>
        <div className="d-flex gap-2">
          {[
            { value: "1", label: "Yes" },
            { value: "2", label: "No" },
          ].map((option) => (
            <div key={option.value}>
              <input
                type="radio"
                className="btn-check"
                name="interested_employment"
                id={`interested_${option.value}`}
                checked={
                  formData.employement?.interestedEmployment
                    ?.isInterestedEmployment === option.value
                }
                onChange={() =>
                  handleChange(
                    "interestedEmployment",
                    "isInterestedEmployment",
                    option.value,
                  )
                }
                disabled={!isEditing}
              />
              <label
                className="btn btn-outline-primary"
                htmlFor={`interested_${option.value}`}
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Volunteer */}
      <div className="mb-4">
        <label className="form-label text-primary">
          Do you volunteer currently?
        </label>
        <div className="d-flex gap-2">
          {[
            { value: "1", label: "Yes" },
            { value: "2", label: "No" },
          ].map((option) => (
            <div key={option.value}>
              <input
                type="radio"
                className="btn-check"
                name="volunteer_currently"
                id={`volunteer_${option.value}`}
                checked={
                  formData.employement?.volunteerCurrently
                    ?.isVolunteerCurrently === option.value
                }
                onChange={() =>
                  handleChange(
                    "volunteerCurrently",
                    "isVolunteerCurrently",
                    option.value,
                  )
                }
                disabled={!isEditing}
              />
              <label
                className="btn btn-outline-primary"
                htmlFor={`volunteer_${option.value}`}
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
