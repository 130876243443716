// HousingSection.jsx
export const HousingSection = ({ formData, isEditing, setFormData }) => {
  return (
    <section className="mb-5">
      <h2 className="h4 mb-3">Housing & Transportation</h2>

      {/* Current Living Situation */}
      <div className="mb-4">
        <label className="form-label text-primary">
          Choose the statement below that best describes your current living
          situation
        </label>
        <div className="d-flex flex-wrap">
          {[
            { value: "1", label: "I have a steady place to live" },
            {
              value: "2",
              label:
                "I have a place to live today, but I am worried about losing it in the future",
            },
            {
              value: "3",
              label:
                "I do not have a steady place to live (I am temporarily staying with others, in a hotel, in a shelter, living outside on the street, on a beach, in a car, abandoned building, bus or train station, or in a park)",
            },
          ].map((option) => (
            <div key={option.value} className="w-100 mb-2">
              <input
                type="radio"
                className="form-check-input"
                name="living_situation"
                id={`living_situation_${option.value}`}
                checked={
                  formData.currentlyLiveSituation.currentlyLiveSituation ===
                  option.value
                }
                onChange={() => {
                  if (!isEditing) return;
                  setFormData((prev) => ({
                    ...prev,
                    currentlyLiveSituation: {
                      ...prev.currentlyLiveSituation,
                      currentlyLiveSituation: option.value,
                    },
                  }));
                }}
                disabled={!isEditing}
              />
              <label
                className="form-check-label ms-2"
                htmlFor={`living_situation_${option.value}`}
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Current Living Place */}
      <div className="mb-4">
        <label className="form-label text-primary">
          Where do you currently live?
        </label>
        <div className="d-flex flex-wrap gap-2">
          {[
            { key: "home", label: "Home" },
            { key: "apartment", label: "Apartment" },
            { key: "assistedLiving", label: "Assisted Living" },
            { key: "nursingHome", label: "Nursing Home" },
            { key: "homeless", label: "Homeless" },
            { key: "other", label: "Other" },
          ].map((option) => (
            <div key={option.key} className="w-100">
              <input
                type="radio"
                className="btn-check"
                name="current_live"
                id={`live_${option.key}`}
                checked={formData.currentLive[option.key] === "1"}
                onChange={() => {
                  if (!isEditing) return;
                  const updatedLive = {
                    home: "",
                    apartment: "",
                    assistedLiving: "",
                    nursingHome: "",
                    homeless: "",
                    other: "",
                  };
                  updatedLive[option.key] = "1";
                  setFormData((prev) => ({
                    ...prev,
                    currentLive: {
                      ...prev.currentLive,
                      ...updatedLive,
                    },
                  }));
                }}
                disabled={!isEditing}
              />
              <label
                className="btn btn-outline-primary w-100"
                htmlFor={`live_${option.key}`}
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Living Problems */}
      <div className="mb-4">
        <label className="form-label text-primary">
          Think about the place you live. Do you have problems with any of the
          following? (Choose all that apply)
        </label>
        <div className="d-flex flex-wrap gap-2">
          {[
            { key: "bugsAntsMice", label: "Pests such as bugs, ants or mice" },
            { key: "mold", label: "Mold" },
            { key: "leadPaintOrPipes", label: "Lead Paint or Pipes" },
            { key: "lackOfHeat", label: "Lack of Heat" },
            { key: "ovenOrStove", label: "Oven or Stove not working" },
            { key: "waterLeaks", label: "Water Leaks" },
            { key: "none", label: "None of the above" },
          ].map((option) => (
            <div key={option.key} className="w-100">
              <input
                type="checkbox"
                className="btn-check"
                id={`problem_${option.key}`}
                checked={formData.thinkAboutPlace[option.key] === "1"}
                onChange={() => {
                  if (!isEditing) return;
                  setFormData((prev) => ({
                    ...prev,
                    thinkAboutPlace: {
                      ...prev.thinkAboutPlace,
                      [option.key]:
                        prev.thinkAboutPlace[option.key] === "1" ? "" : "1",
                    },
                  }));
                }}
                disabled={!isEditing}
              />
              <label
                className="btn btn-outline-primary w-100"
                htmlFor={`problem_${option.key}`}
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      {/* Living With */}
      <div className="mb-4">
        <label className="form-label text-primary">
          Who do you currently live with?
        </label>
        <div className="d-flex flex-wrap gap-2">
          {[
            { key: "alone", label: "Alone" },
            { key: "spouse", label: "Spouse" },
            { key: "partner", label: "Partner" },
            { key: "relative", label: "Relative" },
            { key: "family", label: "Family" },
            { key: "friend", label: "Friend" },
            { key: "personalCareWorker", label: "Personal Care Worker" },
          ].map((option) => (
            <div key={option.key} className="w-100">
              <input
                type="radio"
                className="btn-check"
                name="living_with"
                id={`with_${option.key}`}
                checked={formData.currentlyLivingWith[option.key] === "1"}
                onChange={() => {
                  if (!isEditing) return;
                  const updatedLivingWith = {
                    alone: "",
                    spouse: "",
                    partner: "",
                    relative: "",
                    family: "",
                    friend: "",
                    personalCareWorker: "",
                  };
                  updatedLivingWith[option.key] = "1";
                  setFormData((prev) => ({
                    ...prev,
                    currentlyLivingWith: {
                      ...prev.currentlyLivingWith,
                      ...updatedLivingWith,
                    },
                  }));
                }}
                disabled={!isEditing}
              />
              <label
                className="btn btn-outline-primary w-100"
                htmlFor={`with_${option.key}`}
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>

        {/* Alone Description */}
        {formData.currentlyLivingWith.alone === "1" && (
          <div className="mt-3">
            <label className="form-label">Describe</label>
            <textarea
              className="form-control"
              value={formData.currentlyLivingWith.aloneSubAnswer.describe}
              onChange={(e) => {
                if (!isEditing) return;
                setFormData((prev) => ({
                  ...prev,
                  currentlyLivingWith: {
                    ...prev.currentlyLivingWith,
                    aloneSubAnswer: {
                      ...prev.currentlyLivingWith.aloneSubAnswer,
                      describe: e.target.value,
                    },
                  },
                }));
              }}
              disabled={!isEditing}
            />
          </div>
        )}
      </div>

      {/* Utility Bills */}
      <div className="mb-4">
        <label className="form-label text-primary">
          In the last 12 months have you had difficulty paying your utility
          bills?
        </label>
        <div className="d-flex">
          {[
            { value: "1", label: "Yes" },
            { value: "2", label: "No" },
          ].map((option) => (
            <div key={option.value} className="me-3">
              <input
                type="radio"
                className="btn-check"
                name="utility_bills"
                id={`utility_${option.value}`}
                checked={
                  formData.lastTwelveMonths.isLastTwelveMonths === option.value
                }
                onChange={() => {
                  if (!isEditing) return;
                  setFormData((prev) => ({
                    ...prev,
                    lastTwelveMonths: {
                      ...prev.lastTwelveMonths,
                      isLastTwelveMonths: option.value,
                    },
                  }));
                }}
                disabled={!isEditing}
              />
              <label
                className="btn btn-outline-primary"
                htmlFor={`utility_${option.value}`}
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>

        {/* Utility Bills Select */}
        {formData.lastTwelveMonths.isLastTwelveMonths === "1" && (
          <div className="mt-3">
            <select
              className="form-select"
              value={
                formData.lastTwelveMonths.lastTwelveMonthsSubAnswers
                  .lastTwelveMonthSelect
              }
              onChange={(e) => {
                if (!isEditing) return;
                setFormData((prev) => ({
                  ...prev,
                  lastTwelveMonths: {
                    ...prev.lastTwelveMonths,
                    lastTwelveMonthsSubAnswers: {
                      ...prev.lastTwelveMonths.lastTwelveMonthsSubAnswers,
                      lastTwelveMonthSelect: e.target.value,
                    },
                  },
                }));
              }}
              disabled={!isEditing}
            >
              <option value="">Please Select</option>
              <option value="1">
                I didn't have enough money to pay my utility bills
              </option>
              <option value="2">
                I received government subsidies to assist with my utility bills
              </option>
              <option value="3">Utilities were disconnected</option>
              <option value="4">Other</option>
            </select>
          </div>
        )}
      </div>

      {/* Transportation */}
      <div className="mb-4">
        <label className="form-label text-primary">
          Has lack of reliable transportation kept you from medical
          appointments, meetings, work or from getting things needed for daily
          living?
        </label>
        <div className="d-flex">
          {[
            { value: "1", label: "Yes" },
            { value: "2", label: "No" },
          ].map((option) => (
            <div key={option.value} className="me-3">
              <input
                type="radio"
                className="btn-check"
                name="transportation"
                id={`transport_${option.value}`}
                checked={formData.dailyLiving.isDailyLiving === option.value}
                onChange={() => {
                  if (!isEditing) return;
                  setFormData((prev) => ({
                    ...prev,
                    dailyLiving: {
                      ...prev.dailyLiving,
                      isDailyLiving: option.value,
                    },
                  }));
                }}
                disabled={!isEditing}
              />
              <label
                className="btn btn-outline-primary"
                htmlFor={`transport_${option.value}`}
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
