import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  Box,
  Alert,
  TableFooter,
} from "@mui/material";

const Activities = ({ data }) => {
  const [formData, setFormData] = useState({
    difficultyWithActivities: {
      bathing: { score: 0 },
      dressing: { score: 0 },
      toileting: { score: 0 },
      transferring: { score: 0 },
      continence: { score: 0 },
      feeding: { score: 0 },
      totalScore: "0",
      totalScoreComment: "",
      comment: "",
    },
  });
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (data && data.data) {
      setFormData(data.data);
    }
  }, [data]);

  const handleScoreChange = (activity, value) => {
    setFormData((prevData) => ({
      ...prevData,
      difficultyWithActivities: {
        ...prevData.difficultyWithActivities,
        [activity]: { score: parseInt(value) },
      },
    }));
    setIsEditing(true);
  };

  const calculateTotalScore = () => {
    const activities = formData.difficultyWithActivities;
    return Object.keys(activities)
      .filter(
        (key) =>
          key !== "totalScore" &&
          key !== "totalScoreComment" &&
          key !== "comment",
      )
      .reduce((total, key) => total + activities[key].score, 0);
  };

  const getScoreIndication = (score) => {
    if (score === 6) return "Full Function";
    if (score >= 3 && score <= 5) return "Moderate Impairment";
    return "Severe Functional Impairment";
  };

  const handleSubmit = async () => {
    const numYear = user.year;
    //handle any exceptions that occur due to year value
    const yearToUse = (function () {
      if (!numYear) return new Date().getFullYear();

      const validYears = [2023, 2024];
      const yearStr = String(numYear).trim();
      const yearNum = Number(yearStr);

      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();
    try {
      const payload = {
        memberId: user.memberId, // Replace with actual member ID
        year: yearToUse,
        name: "Activities",
        data: {
          ...formData,
          difficultyWithActivities: {
            ...formData.difficultyWithActivities,
            totalScore: calculateTotalScore().toString(),
          },
        },
      };

      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-activities`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      setMessage("Activities updated successfully!");

      setIsEditing(false);
    } catch (error) {
      console.error("Error updating activities:", error);
      setMessage("Failed to update activities. Please try again.");
    }
  };

  const handleCancel = () => {
    if (data && data.data) {
      setFormData(data.data);
    }
    setIsEditing(false);
    setMessage("");
  };
  const { user = {} } = useSelector((state) => state?.auth ?? {});

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      {message && (
        <Alert
          severity={message.includes("successfully") ? "success" : "error"}
          sx={{ mb: 4 }}
        >
          {message}
        </Alert>
      )}

      <Typography variant="h4" component="h1" gutterBottom>
        Activities of Daily Living
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Katz Index of Independence in Activities of Daily Living
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Evidenced-based functional status instrument
      </Typography>

      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Activities</TableCell>
              <TableCell>Independence Description</TableCell>
              <TableCell>Dependence Description</TableCell>
              <TableCell>Score (0-6)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              {
                name: "bathing",
                independence:
                  "Bathes self completely or needs help in bathing only a single part of the body such as the back, genital area or disabled extremity.",
                dependence:
                  "Need help with bathing more than one part of the body, getting in or out of the tub or shower. Requires total bathing.",
              },
              {
                name: "dressing",
                independence:
                  "Get clothes from closets and drawers and puts on clothes and outer garments complete with fasteners. May have help tying shoes.",
                dependence:
                  "Needs help with dressing self or needs to be completely dressed.",
              },
              {
                name: "toileting",
                independence:
                  "Goes to toilet, gets on and off, arranges clothes, cleans genital area without help.",
                dependence:
                  "Needs help transferring to the toilet, cleaning self or uses bedpan or commode.",
              },
              {
                name: "transferring",
                independence:
                  "Moves in and out of bed or chair unassisted. Mechanical transfer aids are acceptable.",
                dependence:
                  "Needs help in moving from bed to chair or requires a complete transfer.",
              },
              {
                name: "continence",
                independence:
                  "Exercises complete self control over urination and defecation.",
                dependence:
                  "Is partially or totally incontinent of bowel or bladder.",
              },
              {
                name: "feeding",
                independence:
                  "Gets food from plate into mouth without help. Preparation of food may be done by another person.",
                dependence:
                  "Needs partial or total help with feeding or requires parenteral feeding.",
              },
            ].map((activity) => (
              <TableRow key={activity.name}>
                <TableCell>
                  {activity.name.charAt(0).toUpperCase() +
                    activity.name.slice(1)}
                </TableCell>
                <TableCell>{activity.independence}</TableCell>
                <TableCell>{activity.dependence}</TableCell>
                <TableCell>
                  <Select
                    value={
                      formData.difficultyWithActivities[activity.name].score
                    }
                    onChange={(e) =>
                      handleScoreChange(activity.name, e.target.value)
                    }
                    disabled={!isEditing}
                    size="small"
                    variant="outlined"
                  >
                    {[...Array(7)].map((_, i) => (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3} align="right">
                <strong>Total Points:</strong>
              </TableCell>
              <TableCell>{calculateTotalScore()}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        {!isEditing ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              sx={{ mr: 2 }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </>
        )}
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" component="h3" gutterBottom>
          Scoring Guidelines
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Score</TableCell>
              <TableCell>Indication</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>6</TableCell>
              <TableCell>Full Function</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3-5</TableCell>
              <TableCell>Moderate Impairment</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>0-2</TableCell>
              <TableCell>Severe Functional Impairment</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" component="h3" gutterBottom>
          Current Status
        </Typography>
        <Paper sx={{ p: 3 }}>
          <Typography>
            <strong>Total Score:</strong> {calculateTotalScore()}
          </Typography>
          <Typography>
            <strong>Status:</strong> {getScoreIndication(calculateTotalScore())}
          </Typography>
          <Typography>
            <strong>Comments:</strong>{" "}
            {formData.difficultyWithActivities.comment ||
              "No comments available."}
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default Activities;
