import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

import axios from "axios";

const Demograph = ({ data }) => {
  // Initialize state with default values
  const [formData, setFormData] = useState({
    plan: "",
    program: "",
    lob: "",
    region: "",
    aligned: "",
    name: "",
    genderAtBirth: "",
    dob: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    memberId: "",
    email: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");

  // Use useEffect to update formData when data prop changes
  useEffect(() => {
    if (data && data.data) {
      setFormData(data.data);
    }
  }, [data]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setIsEditing(true);
  };

  // Handle form submission
  const handleSubmit = async () => {
    const numYear = user.year;
    const yearToUse = (function () {
      if (!numYear) return new Date().getFullYear();

      const validYears = [2023, 2024];
      const yearStr = String(numYear).trim();
      const yearNum = Number(yearStr);

      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();

    try {
      // Prepare the payload for the PUT request
      const payload = {
        memberId: user.memberId, //formData.memberId,
        year: yearToUse, //new Date().getFullYear(),
        name: "Demographics",
        data: formData,
      };
      const token = localStorage.getItem("token"); // or from your auth context

      // Make the API call with the token in the headers
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-demographics`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      // Handle successful update
      setMessage("Form updated successfully! Reload Page to see the changes");
      setIsEditing(false);

      // Optional: You might want to refresh the data or update parent component
    } catch (error) {
      // Handle any errors
      console.error("Error updating demographics:", error);
      setMessage("Failed to update form. Please try again.");
    }
  };

  // Cancel editing
  const handleCancel = () => {
    if (data && data.data) {
      setFormData(data.data);
    }
    setIsEditing(false);
    setMessage("");
  };

  const user = useSelector((state) => state.auth.user);

  // If no data is passed, return null
  if (!data) return null;

  return (
    <div
      style={{ padding: "15px" }}
      className="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      {message && (
        <Alert
          severity={message.includes("successfully") ? "success" : "error"}
          className="mb-4"
        >
          {message ||
            (message.includes("successfully")
              ? "Here is a gentle confirmation that your action was successful."
              : "Something went wrong. Please try again.")}
        </Alert>
      )}

      <form>
        <h2 className="mb-4">Demograph</h2>

        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <label htmlFor="plan" className="form-label">
              Plan
            </label>
            <input
              type="text"
              className="form-control"
              id="plan"
              value={formData.plan || ""}
              onChange={handleInputChange}
              readOnly={!isEditing}
              style={{ backgroundColor: isEditing ? "white" : "#e9ecef" }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="program" className="form-label">
              Program
            </label>
            <input
              type="text"
              className="form-control"
              id="program"
              value={formData.program || ""}
              onChange={handleInputChange}
              readOnly={!isEditing}
              style={{ backgroundColor: isEditing ? "white" : "#e9ecef" }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="lob" className="form-label">
              LOB
            </label>
            <input
              type="text"
              className="form-control"
              id="lob"
              value={formData.lob || ""}
              onChange={handleInputChange}
              readOnly={!isEditing}
              style={{ backgroundColor: isEditing ? "white" : "#e9ecef" }}
            />
          </div>
        </div>

        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <label htmlFor="region" className="form-label">
              Region
            </label>
            <input
              type="text"
              className="form-control"
              id="region"
              value={formData.region || ""}
              onChange={handleInputChange}
              readOnly={!isEditing}
              style={{ backgroundColor: isEditing ? "white" : "#e9ecef" }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="aligned" className="form-label">
              Aligned
            </label>
            <input
              type="text"
              className="form-control"
              id="aligned"
              value={formData.aligned || ""}
              onChange={handleInputChange}
              readOnly={!isEditing}
              style={{ backgroundColor: isEditing ? "white" : "#e9ecef" }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="name" className="form-label">
              Member Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={formData.lastName + ", " + formData.firstName || ""}
              onChange={handleInputChange}
              readOnly={true}
              style={{ backgroundColor: "#e9ecef" }}
            />
          </div>
        </div>

        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <label className="form-label">Gender of Birth</label>
            <div className="border p-2">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="genderOfBirth"
                  id="female"
                  value="1"
                  checked={formData.genderAtBirth === "1"}
                  readOnly={true}
                  disabled={false}
                  style={{ backgroundColor: "#e9ecef", left: "20%" }}
                />
                <label className="form-check-label" htmlFor="female">
                  Female
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="genderOfBirth"
                  id="male"
                  value="2"
                  checked={formData.genderAtBirth === "2"}
                  readOnly={true}
                  disabled={false}
                  style={{ backgroundColor: "#e9ecef", left: "10%" }}
                />
                <label className="form-check-label" htmlFor="male">
                  Male
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="dob" className="form-label">
              Date of Birth
            </label>
            <input
              type="date"
              className="form-control"
              id="dob"
              value={formData.dob ? formData.dob.split("T")[0] : ""}
              onChange={handleInputChange}
              readOnly={true}
              style={{ backgroundColor: "#e9ecef" }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="phoneNumber" className="form-label">
              Phone Number
            </label>
            <input
              type="tel"
              className="form-control"
              id="phoneNumber"
              value={formData.phoneNumber || ""}
              onChange={handleInputChange}
              readOnly={!isEditing}
              style={{ backgroundColor: isEditing ? "white" : "#e9ecef" }}
            />
          </div>
        </div>

        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <label htmlFor="address" className="form-label">
              Address
            </label>
            <input
              type="text"
              className="form-control"
              id="address"
              value={formData.address || ""}
              onChange={handleInputChange}
              readOnly={!isEditing}
              style={{ backgroundColor: isEditing ? "white" : "#e9ecef" }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="city" className="form-label">
              City
            </label>
            <input
              type="text"
              className="form-control"
              id="city"
              value={formData.city || ""}
              onChange={handleInputChange}
              readOnly={!isEditing}
              style={{ backgroundColor: isEditing ? "white" : "#e9ecef" }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="state" className="form-label">
              State
            </label>
            <input
              type="text"
              className="form-control"
              id="state"
              value={formData.state || ""}
              onChange={handleInputChange}
              readOnly={!isEditing}
              style={{ backgroundColor: isEditing ? "white" : "#e9ecef" }}
            />
          </div>
        </div>

        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <label htmlFor="zip" className="form-label">
              Zip Code
            </label>
            <input
              type="text"
              className="form-control"
              id="zip"
              value={formData.zip || ""}
              onChange={handleInputChange}
              readOnly={!isEditing}
              style={{ backgroundColor: isEditing ? "white" : "#e9ecef" }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="memberId" className="form-label">
              Member Identification Number
            </label>
            <input
              type="text"
              className="form-control"
              id="memberId"
              value={formData.memberId || ""}
              onChange={handleInputChange}
              readOnly={true}
              style={{ backgroundColor: "#e9ecef" }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="email" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={formData.email || ""}
              onChange={handleInputChange}
              readOnly={true}
              style={{ backgroundColor: isEditing ? "white" : "#e9ecef" }}
            />
          </div>
        </div>

        <div className="col-md-12 mt-4 text-center">
          {!isEditing ? (
            // Edit button - only changes editing state
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </button>
          ) : (
            <>
              <button
                type="button" // Changed from 'submit' to 'button'
                className="btn btn-success me-2"
                onClick={handleSubmit} // API call only happens here
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default Demograph;
